import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'

export default {
  async selectFilterLookups (Vue) {
    const apiFilterEstadoActivo = new APIFilter()
    apiFilterEstadoActivo.addGT('estado', 0)
    let apiCalls = []
    apiCalls.push({ name: 'selectTfichero', method: 'tfichero.select', params: { filter: apiFilterEstadoActivo } })
    apiCalls.push({ name: 'selectVTficheroClasificacion', method: 'vTficheroClasificacion.select' })
    return await Vue.$api.batchCall(apiCalls)
  },
  async selectFichero (
    Vue, filter, search, sorter, page, idtabla, id, esTecnico, esComercial, esVigilante
  ) {
    const apiFilter = new APIFilter()
    const apiFilterVisualizacion = Vue.$online.fichero.buildApiFilterVisualizacion(id, idtabla, esTecnico, esComercial, esVigilante)
    apiFilter.addNestedFilter(apiFilterVisualizacion)
    if (search) {
      const apiFilterSearch = new APIFilter()
      apiFilterSearch
        .setOperator(API_FILTER_OPERATORS.OR)
        .addILike('nombre_fichero', search)
        .addILike('observaciones', search)
      apiFilter.addNestedFilter(apiFilterSearch)
    }
    if (filter.nombreFichero.value) {
      apiFilter.addILike(filter.nombreFichero.field, filter.nombreFichero.value)
    }
    if (filter.observaciones.value) {
      apiFilter.addILike(filter.observaciones.field, filter.observaciones.value)
    }
    if (filter.tipoFichero.value) {
      apiFilter.addILike(filter.tipoFichero.field, filter.tipoFichero.value)
    }
    if (filter.tipoFicheroClasificacion.value) {
      apiFilter.addILike(filter.tipoFicheroClasificacion.field, filter.tipoFicheroClasificacion.value)
    }
    const resp = await Vue.$api.call('fichero.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectFicheroRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter
      .setOperator(API_FILTER_OPERATORS.AND)
      .addIn('idfichero', pks)
    const resp = await Vue.$api.call('fichero.select', {
      filter: apiFilter
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  deleteFichero (Vue, idfichero) {
    return Vue.$api.call('fichero.delete', { idfichero })
  },
}
